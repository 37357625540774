<script>
export default {
  name: 'BgSpiderWeb',

  props: {
    w: {
      type: [Number, String],
      default: null,
    },
    h: {
      type: [Number, String],
      default: null,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 200 200"
    role="presentation"
  >
    <g id="spider-web">
      <g>
        <defs>
          <path
            id="SVGID_1_"
            d="M107.7,115c0.1,0.1,0.1,0.2,0.1,0.1C107.7,115.1,107.7,115.1,107.7,115z M171.6-7.5l-21.7,36.8l22.4-36.4
        L171.6-7.5z M213.1,132.8l-98-35.9l96.8,26.9l0.6-2.1L116.3,95l95.3,9.3l0.1-0.8l-0.5-0.1c0-1.3-0.1-5.8-0.4-9.2
        c-0.4-3.5,0.8-8.5,1-9.5l1-0.1l-0.1-0.8l-93.9,8.4l79.9-13.1l-0.1-0.8l-81.1,13.3l3.4-1.1l91.9-24.7l-0.2-0.8l-83.3,22.2
        L212.3,61l-0.7-2.1L111.4,92.3l99.9-40.8l-0.3-0.7L115.6,90l-2,0.7l94-49.1l-0.4-0.7l-89.7,46.9l91.2-56.1l-0.4-0.7l-84.1,52
        l84-60l-0.5-0.7L119.2,86l88.5-77.1l-0.5-0.6l-90.4,79l91-94.6l-0.6-0.6l-94.8,98.2l37.6-61.1l-26.8,42l3.2-5.7l41.5-77.6
        l-0.7-0.4l-52.9,98l49-95.6l-0.7-0.4L110.1,91.8l-0.5,0.2l5.1-12.3l44.8-93l-0.7-0.4l-38.3,79.5L145,7.2l-0.7-0.3L114,79.3
        l-3.7,9l37-99.4l-0.8-0.3L108.8,90.5L137.6-10l-0.8-0.2L109,85.3l25.2-93.6l-2.1-0.6l-22,86.8l18.7-84.9L128-7.1l-22.4,98.2
        l-0.2-0.7l22.5-105.7l-0.8-0.2l-19.1,90l14.7-88.7l-0.8-0.1l-16.4,99l4.8-91.4l-0.8,0l-4.7,94.1l-5.4-66.7l-0.8,0.1l5.9,68.3
        l-24-95.2l-1.5,0.4c-1-0.4-2.5-1-3.1-0.8c-0.8,0.4-3.3,1.4-4.9,1.5c-1.6,0.2-2.4,0.2-3.4,0.2C66.8-5,65.6-5.4,65-5.7l-1.1-2.6
        l-1,0.4l34.7,84.2L50.5-12.9l0,0l0,0l-1,0.5l-1,0.5l51.9,101.2L41.8-11.4L41.1-11l55.5,96.5L62,33.5L61.3,34l37.6,55.3L20-11.2
        l-0.6,0.5l81.1,102.8L-1.4-6.5l-1.5,1.5l81.4,78.7L24.2,25.7l-0.5,0.6l68.1,60.2l0.5,0.5L-17.9,3l-0.5,0.6L97.2,91.8l0.6,0.6
        L-4.3,31.8l-0.4,0.7L99.5,94.3l0.2,0.4L46.6,64.2l-0.4,0.7l53.3,30.6l2.3,2.3l-111-64.5L-9.6,34l88.5,52L-5,40.4l-0.4,0.7
        l106.2,57.8c-0.5-0.2-107.2-54-107.2-54l-1.5,2.9l107.2,53C99,100.7,76,90.5,76,90.5s17.7,10,17.6,10.1l-0.4-0.1l-86.5-44
        l-0.4,0.7l81.6,41.5L-2.7,63.6L-3,64.3l90.3,35.1L-4,81.4l-0.4,2.1l94.6,18.6L-4,111.6l0.1,0.8l0.4,0c0.1,0.8,0.3,3.2,1.3,5.8
        c0.6,1.5,0.8,2,0.9,2.4c0.1,0.4,0.2,0.7,0.8,2.2c0.2,0.5,0.4,1,0.7,1.5c0.9,1.9,1.6,3.4,1.9,6.8c0.3,3.6,1.2,9.5,1.4,11.2l-5,2.2
        l0.3,0.7l93.9-41.6l-77.7,65.2l0.5,0.6l77.7-65.3L37.2,189l0.7,0.4L93,106.1l-38.4,88.6l0.7,0.3L93,108.2l-16.4,56.1l0.8,0.2
        l16.9-57.7l-8.1,67.4l0.8,0.1l7.7-63.8l4.2,77.4l0.5-32.1l-2.8-48.4l4.9,36.5l5.4,41.2l0,0l0.5,3.9l0,0l0.2,1.2l0.2-0.1
        c0,0,3.4-1.6,5.9-2.6c2.5-1,8.7-4.5,8.8-4.5l0.1-0.1l0-0.1l0.2-0.1l-23-68.5l27.7,68.3l0.7-0.3l-29-71.5l35.9,70.7l3.7,7.8l2-0.9
        l-16.4-34.9l18.5,37.1l0.7-0.4l-34.2-68.8l-1.2-2.5l38.8,70.8l0.7-0.4l-45-82.1l-2-4.2c0.3,0.3,6.1,10.5,6.8,11.8
        c-0.7-1.5-6.7-14.4-6.7-14.4s0-0.1,0.1-0.1l46.3,83.4l0.7-0.4l-43.7-78.8l58.2,80l0.2,0.2l0,0l0.3-0.2l0.3-0.2l-59.8-82.3
        l5.2,5.2l66.7,78.6l-29.2-36.5l-20.6-25l58.5,59.2l0.6-0.6L120,117.6l-2.3-2.8l74.2,68.3l0.5-0.6l-78.5-72.3l-5.5-6.6l89.1,79.2
        l0.5-0.6l-40.2-35.8l0.4-0.5l-18.1-16.6L200,181l1.4-1.6l-96.2-83.1l98.5,82.7l0.5-0.6l-98-82.3l100.9,74.6l0.5-0.6l-75.1-56.5
        l73.9,52.7l0.5-0.7l-87.2-62.2l87.3,55.8l0.4-0.7l-90-57.6l0.8,0.4l87.4,47.7l0.4-0.7L126.6,105l79.1,34.5l0.2-0.4l0.2-0.4l0,0
        l0,0l-93.2-41.8l100,36.6L213.1,132.8z M3.8,142.2c-0.3-1.8-1.1-7.6-1.4-11.1c-0.3-3.5-1.1-5.1-1.9-6.9c-0.2-0.5-0.4-0.9-0.7-1.5
        c-0.6-1.4-0.7-1.7-0.8-2.1c-0.1-0.4-0.3-0.9-0.9-2.4c-1-2.6-1.3-5-1.3-5.8l2.5-0.2c0.6,1.1,2.3,4.3,3.7,6.8
        c1.7,3.1,3.8,8.1,4.3,10.4c0.3,1.1,0.2,1.8,0.2,2.4c0,0.6,0,1.1,0.3,2c0.5,1.3,1.4,4.6,1.7,6L3.8,142.2z M9.8,139.5
        c-0.4-1.4-1.2-4.6-1.7-5.9c-0.3-0.9-0.3-1.3-0.3-1.9c0-0.6,0-1.2-0.2-2.4c-0.5-2.3-2.6-7.3-4.3-10.5c-1.3-2.4-3-5.5-3.7-6.7
        l5-0.5c0.5,1,2.6,4.7,3.9,7c1.4,2.5,1.5,2.6,2.4,5.7l0.2,0.6c1.1,3.5,3.3,11.3,3.7,12.4L9.8,139.5z M14.9,137.2
        c-0.4-1.3-2.6-9-3.6-12.4l-0.2-0.6c-0.9-3.1-1-3.2-2.4-5.8c-1.3-2.2-3.3-5.8-3.9-6.9l6.2-0.6c0.3,0.6,1.1,1.9,2.5,3.5
        c1.7,2,3.4,4.6,4.2,6.4c0.5,1.1,0.7,2.1,1,3.1c0.1,0.6,0.3,1.2,0.5,1.8c0.2,0.8,0.2,1.3,0.3,1.8c0,0.6,0,1.1,0.4,1.9
        c0.6,1.1,1.1,4,1.3,5L14.9,137.2z M21.2,134.4c-0.2-1.1-0.7-3.9-1.3-5c-0.4-0.7-0.4-1.2-0.4-1.8c0-0.5,0-1.1-0.3-1.9
        c-0.2-0.6-0.3-1.2-0.5-1.8c-0.3-1-0.5-2-1-3.2c-0.8-1.9-2.4-4.4-4.2-6.5c-1.3-1.5-2-2.8-2.4-3.4l6.1-0.6c0.3,0.5,1.1,1.7,2,2.8
        c0.7,0.8,0.8,1.1,1,1.6c0.2,0.4,0.4,0.8,0.9,1.8c0.2,0.4,0.5,0.8,0.7,1.1c0.8,1.3,1.2,2.1,2,4.2c0.2,0.5,0.3,0.9,0.5,1.3
        c0.5,1.5,0.7,1.8,1.1,3.9c0.4,2,1.2,4.2,1.6,5L21.2,134.4z M27.1,131.8c-0.3-0.8-1.1-2.9-1.5-4.9c-0.4-2.1-0.6-2.4-1.1-3.9
        c-0.1-0.4-0.3-0.8-0.5-1.3c-0.8-2.2-1.2-3-2-4.3c-0.2-0.3-0.4-0.7-0.7-1.1c-0.5-0.9-0.7-1.4-0.9-1.7c-0.2-0.5-0.3-0.8-1-1.7
        c-0.8-1-1.5-2.1-1.9-2.7l6.5-0.6c0.2,0.4,0.5,1.1,1.3,2.3c1.3,2.3,1.9,3.6,2.3,4.8c0.3,0.7,0.5,1.3,0.9,2
        c0.6,1.1,1.5,2.2,2.2,3.1c0.3,0.4,0.7,0.9,0.9,1.2c0.7,1,1.5,5,1.7,6.1L27.1,131.8z M33.6,129c-0.2-1.2-1-5.1-1.7-6.1
        c-0.3-0.4-0.6-0.8-0.9-1.2c-0.7-0.9-1.5-1.9-2.2-3.1c-0.4-0.6-0.6-1.2-0.9-1.9c-0.5-1.1-1-2.5-2.3-4.8c-0.7-1.3-1.1-1.9-1.2-2.2
        l6.6-0.7c0.2,0.6,0.7,2.2,1.5,4.5c0.4,1.1,0.6,1.9,0.7,2.5c0.2,1,0.4,1.7,1.2,3c0.3,0.4,0.5,0.8,0.7,1c0.8,1.2,1.1,1.6,1.6,3.6
        c0.5,1.8,1.7,2.6,2.3,2.9L33.6,129z M44.2,124.2l-5,2.2c-0.6-0.2-1.9-1-2.4-2.8c-0.6-2-0.8-2.5-1.6-3.7c-0.2-0.3-0.4-0.6-0.7-1
        c-0.8-1.3-0.9-1.9-1.2-2.9c-0.1-0.6-0.3-1.4-0.7-2.6c-0.8-2.3-1.3-3.9-1.5-4.5l7.5-0.8c-0.2,0.4,0.1,1.1,0.8,2.1
        c1.2,1.6,1.3,1.8,1.6,2.7c0.2,0.4,0.4,1,0.8,2c0.4,1,0.7,1.6,1,2.1c0.5,1.1,0.7,1.4,1,2.9C44.5,121.7,44.3,123.6,44.2,124.2z
         M44.5,124.1c0.1-0.8,0.2-2.6-0.2-4.3c-0.4-1.5-0.6-1.9-1.1-2.9c-0.2-0.5-0.5-1.1-1-2.1c-0.4-1-0.7-1.6-0.8-2
        c-0.4-1-0.5-1.2-1.7-2.8c-0.7-0.9-0.9-1.5-0.8-1.8c0,0,0.1-0.1,0.1-0.1l4.2-0.4c0.4,0.8,1.7,3.7,2.8,5.8c0.1,0.3,0.2,0.5,0.4,0.7
        c1,1.8,1.1,2.2,1.3,5.1c0.1,1.4,0.5,2.3,1.2,2.8c0.1,0,0.2,0.1,0.2,0.1L44.5,124.1z M49.5,121.9c-0.1,0-0.3-0.1-0.4-0.2
        c-0.6-0.4-1-1.2-1.1-2.5c-0.2-2.9-0.4-3.3-1.3-5.2c-0.1-0.2-0.2-0.4-0.4-0.7c-1.1-2.1-2.4-4.8-2.8-5.7l5.6-0.6
        c-0.2,0.7,0.3,1.9,0.7,2.8c1,1.8,1.4,2.7,2.2,4.7c0.7,1.7,1.6,4.4,2,5.3L49.5,121.9z M54.3,119.8c-0.3-1-1.3-3.7-2-5.3
        c-0.8-2.1-1.2-2.9-2.2-4.7c-0.7-1.2-0.9-2.2-0.7-2.7l2.7-0.3l2.1,5.1l3.2,6.4L54.3,119.8z M57.8,118.2l-3.2-6.4l-2.1-5.1l3.2-0.3
        l1,2.3l1.8,2.4c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.3,0.5,0.5,0.8,1.8c0.3,1.2,0.8,3,1.1,3.8L57.8,118.2z M60.7,116.9
        c-0.2-0.8-0.7-2.5-1-3.7c-0.3-1.3-0.5-1.5-0.8-1.9c-0.1-0.1-0.1-0.2-0.2-0.3l-1.8-2.4l-1-2.2l4-0.4c0.8,1.8,1.8,4.3,1.9,4.5
        c0.1,0.3,0.3,1.9,0.3,1.9l2.1,3L60.7,116.9z M64.4,115.3l-2.1-2.9c0-0.2-0.3-1.5-0.3-1.9c-0.1-0.3-1.4-3.5-1.8-4.5l4-0.4
        l-0.3,1.9l0,0l0,0c0,0.1,1,2.8,1.1,3.2c0.1,0.3,1.3,2.8,1.7,3.6L64.4,115.3z M66.9,114.2c-0.4-0.8-1.6-3.3-1.7-3.6
        c-0.1-0.3-1-2.8-1.1-3.1l0.3-1.9l2.6-0.3c0.3,0.6,0.9,2.1,1.1,2.6c0.1,0.4,0.3,0.6,0.4,0.8c0.1,0.1,0.1,0.3,0.2,0.4
        c0.1,0.3,0.5,1.5,0.5,1.6l0.6,2.3L66.9,114.2z M69.9,112.9l-0.6-2.2c0,0-0.4-1.2-0.5-1.5c0-0.2-0.1-0.3-0.2-0.5
        c-0.1-0.2-0.2-0.4-0.3-0.8c-0.2-0.5-0.8-1.9-1.1-2.6l2.6-0.3c0.1,0.3,0.3,0.7,0.4,0.9c0.1,0.2,0.2,0.5,0.3,0.8
        c0.1,0.3,0.2,0.7,0.3,0.9c0.2,0.3,0.2,0.6,0.3,1c0.1,0.3,0.1,0.5,0.2,0.8c0.1,0.4,0.6,1.7,0.9,2.3L69.9,112.9z M72.6,111.7
        c-0.3-0.6-0.8-1.8-0.9-2.3c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.4-0.2-0.8-0.4-1.1c-0.1-0.2-0.2-0.5-0.3-0.8
        c-0.1-0.4-0.2-0.7-0.4-0.9c-0.1-0.2-0.3-0.5-0.4-0.8l2.2-0.2c0.5,0.7,1.1,1.5,1.1,1.7c0,0.3,0,0.5,0,0.7c0,0.1,0,0.2,0,0.3
        c0,0.2,0.3,0.9,0.7,2.1c0.1,0.4,0.3,0.9,0.4,1.2L72.6,111.7z M74.8,110.7c-0.1-0.3-0.2-0.6-0.4-1.2c-0.3-0.8-0.7-1.8-0.7-2
        c0-0.1,0-0.2,0-0.3c0-0.2,0-0.5,0-0.8c0-0.3-0.6-1.1-1.1-1.7l3.6-0.4c0.2,0.3,0.5,0.6,0.8,1.1c0.1,0.2,0.2,0.3,0.3,0.4
        c0.1,0.2,0.2,0.3,0.5,0.9c0.4,0.7,0.4,2,0.4,2.5L74.8,110.7z M78.4,109.1c0-0.5-0.1-1.7-0.4-2.5c-0.3-0.6-0.4-0.7-0.5-0.9
        c-0.1-0.1-0.2-0.2-0.3-0.4c-0.3-0.4-0.5-0.7-0.7-1l3.8-0.4c0.4,0.5,0.7,1,0.8,1.2c0.1,0.3,0.4,1.3,0.7,2c0,0.1,0.1,0.2,0.1,0.3
        L78.4,109.1z M85.7,105.8c-0.2,0.1-0.3,0.2-0.5,0.3l-3,1.3c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.7-0.7-1.7-0.7-2c0-0.3-0.4-0.8-0.7-1.2
        l4.6-0.5c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.3,0.2,0.5c0,0.1,0.1,0.3,0.1,0.4C85.7,104.8,85.7,105.3,85.7,105.8z
         M86,105.7c0-0.3,0-1-0.1-1.1c0-0.1-0.1-0.2-0.1-0.3c0-0.2-0.1-0.4-0.3-0.6c0-0.1-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1l1.5-0.1
        l0.5,0.7l0.1,1L86,105.7z M87.8,104.9l-0.1-1l-0.4-0.7l1.7-0.2l0.5,0.6l0,0.5L87.8,104.9z M89.8,104l0-0.5l-0.5-0.5l3.5-0.3
        L89.8,104z M97.5,110.1l0.5,1.4l-0.3,0L97.5,110.1z M97.7,111.8l0.3-0.1l0.5,1.5c0,0.2-0.1,0.5-0.2,0.7l-0.3,0.2L97.7,111.8z
         M98,114.4l0.5-0.3l0,0c0-0.1,0.1-0.3,0.1-0.4l0.4,1.2l-0.9,0.3L98,114.4z M122,183c-1.3,0.8-6.2,3.5-8.4,4.3
        c-2.2,0.9-5,2.2-5.8,2.5l-0.1-1c0.4-0.3,2.3-1.8,5.4-2.6c3-0.8,7.3-3,8.7-3.8L122,183z M121.7,182.2c-1.3,0.7-5.7,3-8.6,3.8
        c-2.9,0.8-4.8,2.1-5.4,2.6l-0.4-3.2c0.5-0.2,2.4-1,3.8-1.4c0.6-0.1,0.9-0.2,1.2-0.3c0.5-0.1,0.8-0.2,2.1-0.6
        c1.3-0.4,1.9-0.4,2.7-0.6c0.3,0,0.7-0.1,1.2-0.2c1.2-0.2,2.5-0.9,3.1-1.5L121.7,182.2z M121.2,180.7c-0.5,0.5-1.9,1.3-3.1,1.5
        c-0.5,0.1-0.9,0.1-1.2,0.2c-0.8,0.1-1.4,0.2-2.7,0.6c-1.2,0.4-1.5,0.4-2,0.6c-0.3,0.1-0.6,0.1-1.2,0.3c-1.4,0.4-3.2,1.1-3.7,1.3
        l-0.4-3c0.4,0,1.7,0,3.1-0.7c0.9-0.4,1.7-0.6,2.4-0.9c0.6-0.2,1.1-0.3,1.5-0.5c0.5-0.2,0.7-0.3,0.9-0.4c0.3-0.1,0.6-0.2,1.3-0.5
        c1-0.4,3.2-1.2,4.1-1.5L121.2,180.7z M120.1,177.5c-0.9,0.3-3.1,1-4.1,1.5c-0.7,0.3-0.9,0.4-1.2,0.5c-0.3,0.1-0.5,0.1-1,0.4
        c-0.4,0.2-0.9,0.4-1.5,0.5c-0.7,0.2-1.5,0.5-2.4,0.9c-1.3,0.6-2.6,0.6-3,0.6h0l-0.5-3.7c0.5-0.1,2.2-0.5,3.4-0.7
        c0.9-0.1,1.1-0.2,1.7-0.3c0.3-0.1,0.6-0.2,1.2-0.3c0.9-0.2,1.5-0.5,2.1-0.7c0.6-0.2,1.2-0.5,2-0.7c1.2-0.3,1.9-0.8,2.3-1.1
        L120.1,177.5z M118.9,174c-0.2,0.2-0.8,0.7-2.3,1.2c-0.9,0.3-1.5,0.5-2,0.8c-0.6,0.3-1.2,0.5-2.1,0.7c-0.6,0.1-0.9,0.2-1.2,0.3
        c-0.5,0.1-0.8,0.2-1.6,0.3c-1.1,0.1-2.9,0.5-3.4,0.7l-0.3-2c0.7,0,4.3,0,5.7-0.8c1.3-0.7,1.4-0.7,2.4-1.1l0.6-0.2
        c1.4-0.5,3.1-1.2,3.7-1.4L118.9,174z M118.3,172.1c-0.5,0.2-2.3,0.9-3.7,1.4l-0.6,0.2c-1,0.4-1.1,0.4-2.4,1.1
        c-1.4,0.7-4.9,0.8-5.6,0.8l-0.4-3.5c0.5-0.1,2.3-0.5,3.1-0.8c0.9-0.4,2.1-0.6,4.6-1c1.9-0.3,3.2-1.4,3.8-2L118.3,172.1z
         M117,168.1c-0.5,0.6-1.8,1.7-3.7,2c-2.5,0.4-3.7,0.6-4.7,1c-0.8,0.3-2.8,0.7-3.1,0.8l-0.3-2.2c0.3,0,1.2-0.2,3-1
        c2-0.9,3.4-1.3,4.5-1.5l0.3-0.1c0.8-0.2,2.7-0.5,3.5-0.6L117,168.1z M116.3,166.2c-0.8,0.1-2.6,0.4-3.4,0.6l-0.3,0.1
        c-1.1,0.3-2.6,0.6-4.5,1.5c-1.7,0.8-2.6,0.9-2.9,1l-0.3-2.4c0.4-0.2,1.7-0.9,2.6-1.2c0.2-0.1,0.4-0.1,0.5-0.2
        c0.8-0.3,1.6-0.6,2.3-0.7c0.8-0.1,4.6-0.3,5.4-0.4L116.3,166.2z M115.7,164.3c-1,0.1-4.6,0.3-5.4,0.4c-0.7,0.1-1.5,0.4-2.4,0.7
        c-0.2,0.1-0.4,0.1-0.5,0.2c-0.9,0.3-2.3,1-2.6,1.2l-0.1-0.8c0,0,0.4-0.4,2.5-1.3c0.9-0.4,1.4-0.7,1.9-0.9
        c0.8-0.4,1.2-0.6,2.4-0.8c1.4-0.2,3-0.4,3.6-0.5L115.7,164.3z M115,162.2c-0.6,0.1-2.1,0.3-3.5,0.5c-1.2,0.2-1.7,0.4-2.5,0.8
        c-0.4,0.2-1,0.5-1.9,0.9c-1.5,0.6-2.1,1-2.4,1.2l-0.2-1.8c0.4-0.2,2.1-1,3.6-1.2c1.2-0.2,1.8-0.4,2.5-0.7
        c0.3-0.1,0.7-0.3,1.1-0.4c1-0.3,2.4-0.4,2.9-0.4L115,162.2z M114.5,160.9c-0.6,0-1.9,0.1-2.9,0.4c-0.4,0.1-0.8,0.3-1.2,0.4
        c-0.7,0.3-1.3,0.5-2.4,0.7c-1.5,0.2-3.2,1-3.7,1.2l-0.2-1.9c0.3,0,1.2,0,2.1-0.6c1-0.7,1.4-0.9,2.7-1.2l0.4-0.1
        c0.5-0.2,0.5-0.2,0.9-0.2c0.1,0,0.2,0,0.4,0c0.9,0,2.6-0.5,3.2-0.6L114.5,160.9z M113.8,158.6c-0.6,0.2-2.3,0.6-3.1,0.6
        c-0.2,0-0.3,0-0.4,0c-0.4,0-0.4,0-1,0.2l-0.4,0.1c-1.3,0.4-1.8,0.5-2.8,1.2c-0.7,0.5-1.5,0.6-1.8,0.6c0,0,0,0,0,0l-0.5-3.6
        c0.4-0.1,1.6-0.6,2.2-0.9c0.3-0.2,0.6-0.2,1.1-0.2c0.5-0.1,1.2-0.1,2-0.4c1.2-0.4,2.9-0.7,3.7-0.8L113.8,158.6z M112.6,155.2
        c-0.8,0.1-2.5,0.4-3.7,0.8c-0.9,0.3-1.5,0.3-2,0.4c-0.5,0.1-0.9,0.1-1.2,0.3c-0.5,0.3-1.8,0.8-2.2,0.9l-0.4-3
        c0.1-0.1,0.5-0.5,1.3-0.8c0.6-0.2,1.2-0.3,1.9-0.4c0.4,0,0.8-0.1,1.3-0.2c0.2,0,0.5-0.1,0.9-0.1c1.2,0,2.8,0.2,3.4,0.3
        L112.6,155.2z M110.8,149.8l1.1,3.3c-0.7-0.1-2.2-0.3-3.4-0.3c-0.4,0-0.7,0-0.9,0.1c-0.5,0.1-0.9,0.1-1.3,0.2
        c-0.7,0.1-1.3,0.1-2,0.4c-0.6,0.2-1,0.6-1.3,0.7l-0.3-2.6c0.5,0,3-0.1,3.6-0.6c0.4-0.3,0.7-0.3,1.1-0.4c0.3-0.1,0.7-0.1,1.1-0.3
        C109.5,150,110.5,149.8,110.8,149.8L110.8,149.8z M110.7,149.5c-0.4,0-1.4,0.2-2.2,0.5c-0.4,0.2-0.7,0.2-1,0.3
        c-0.4,0.1-0.8,0.2-1.2,0.5c-0.5,0.4-2.6,0.5-3.4,0.5l-0.4-2.5l0-0.2c0.2-0.1,0.7-0.3,1.4-0.6c0.6-0.3,4.2-0.5,6.3-0.5
        L110.7,149.5z M110,147.3c-1.5,0-5.5,0.2-6.3,0.5c-0.6,0.3-1,0.5-1.3,0.6l-0.5-4l6.2-2.7L110,147.3z M108,141.5l-6.1,2.6
        l-0.5-3.4l6.1-0.7L108,141.5z M107.5,139.8l-6.1,0.7l-0.4-3.4l5-1.9L107.5,139.8z M105.8,134.9l-4.9,1.9l-0.1-0.4l-0.2-1.8l4.4-2
        L105.8,134.9z M105,132.4l-4.4,2l-0.1-1l3.9-2.7L105,132.4z M104.3,130.3l-3.9,2.7l-0.1-0.8l-0.6-4.5c0.4,0,1.2,0,1.8-0.5
        c0.5-0.4,0.9-1,1.2-1.4L104.3,130.3z M102.7,125.5c-0.2,0.4-0.6,1.1-1.2,1.5c-0.6,0.5-1.4,0.5-1.7,0.5l-0.3-2.4
        c0.2-0.2,0.6-0.5,0.9-1c0.3-0.5,1-1,1.4-1.4L102.7,125.5z M101.6,122.4c-0.4,0.3-1.2,1-1.6,1.5c-0.2,0.4-0.5,0.7-0.7,0.8l-0.5-4
        c0.3-0.1,0.9-0.2,1.3-0.3c0.3-0.1,0.5-0.4,0.6-0.7L101.6,122.4z M100.6,119.3c-0.1,0.3-0.3,0.7-0.6,0.8c-0.3,0.1-0.9,0.3-1.2,0.3
        l-0.3-2.2l1.5-0.6L100.6,119.3z M99.9,117.4l-1.4,0.6l-0.1-0.7l1.2-0.7L99.9,117.4z M99.6,116.2l-1.2,0.7l-0.2-1.5l1-0.4
        L99.6,116.2z M124.3,158.4l-27-53c0-0.3-0.2-1.9-0.2-1.9l7.3,15.1c0,0-6.3-15.3-6.3-15.8L124.3,158.4z M65.1-5.3
        c0.7,0.3,1.7,0.6,2.4,0.7c1.1,0.1,1.9,0,3.5-0.2c1.9-0.2,4.9-1.5,5-1.5c0.3-0.2,1.5,0.2,2.5,0.6l-0.2,0l0.4,1.4l-2.6,0.6
        c0,0-2.5,0.9-3.5,1.1c-0.4,0.1-0.6,0.1-0.8,0.1c-0.3,0-0.6,0-1.2,0.3c-0.8,0.4-3,0.4-4,0.5L65.1-5.3z M66.7-1.4
        c1.1,0,3.1-0.1,4-0.5c0.6-0.3,0.9-0.3,1.1-0.3c0.2,0,0.4,0,0.8-0.1c1-0.2,3.4-1.1,3.5-1.1L78.7-4l0.8,3c-0.7,0.5-1.8,1.2-2.9,1.5
        c-1.2,0.3-1.4,0.5-1.6,0.7c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2,0-0.5,0.1-0.9,0.2c-0.8,0.2-1.9,0.5-3.2,0.6c-1,0-1.9,0.1-2.4,0.1
        L66.7-1.4z M68.2,2.4c0.5,0,1.3,0,2.3-0.1C71.8,2.3,73,2,73.8,1.8c0.4-0.1,0.7-0.2,0.9-0.2c0.3,0,0.4-0.1,0.5-0.2
        c0.2-0.1,0.4-0.4,1.5-0.7c1.1-0.3,2.2-0.9,2.9-1.4L80.3,2c-0.2,0.2-0.5,0.6-1.1,1c-1.5,1.1-2.9,1.8-3.6,2c-0.2,0-0.2,0.1-0.3,0.1
        c-0.2,0-0.4,0-1.3,0.5c-1.1,0.5-1.3,0.6-1.8,0.6c-0.1,0-0.2,0-0.3,0c-0.4,0.1-1.6-0.1-2.3-0.2L68.2,2.4z M69.8,6.3
        c0.7,0.1,1.8,0.3,2.2,0.2c0.1,0,0.2,0,0.3,0c0.5,0,0.8-0.1,1.9-0.6c0.9-0.4,1-0.5,1.2-0.5c0.1,0,0.2,0,0.4-0.1
        c0.3-0.1,1.7-0.5,3.7-2c0.5-0.3,0.8-0.6,1-0.9L81.1,5c-0.5,0.6-1.6,1.5-3,2c-1.3,0.4-1.5,0.6-1.6,0.7c-0.1,0.1-0.2,0.1-0.5,0.3
        c-0.4,0.1-0.7,0.3-0.9,0.4c-0.4,0.2-0.8,0.3-1.5,0.5c-0.9,0.2-2,0.2-2.5,0.2L69.8,6.3z M71.1,9.4c0.6,0,1.6,0,2.5-0.3
        c0.7-0.2,1.1-0.4,1.5-0.5c0.3-0.1,0.5-0.2,0.9-0.4c0.4-0.1,0.5-0.2,0.6-0.3c0.1-0.1,0.3-0.3,1.5-0.6c1.4-0.4,2.5-1.3,3-1.9
        l0.9,3.5c-0.5,0.8-1.2,1.8-1.9,2.2c-0.7,0.3-1.3,0.6-1.9,0.8c-0.5,0.2-1,0.3-1.3,0.5c-0.3,0.2-0.5,0.3-0.6,0.4
        c-0.2,0.2-0.3,0.2-0.6,0.3c-0.4,0.1-2.3,0.1-3.1,0.1L71.1,9.4z M72.7,13.4c0.9,0,2.6,0,3.1-0.1c0.4-0.1,0.5-0.2,0.7-0.3
        c0.1-0.1,0.3-0.2,0.6-0.4c0.3-0.2,0.8-0.3,1.3-0.5c0.6-0.2,1.3-0.4,2-0.8c0.7-0.4,1.4-1.3,1.9-2.1l2.1,7.9
        c-0.5,0.4-1,0.9-1.4,1.1l-1.6,1c0,0-1.5,0.5-2.6,0.8c-0.8,0.2-2.2,0.8-2.9,1.1L72.7,13.4z M76,21.3c0.7-0.3,2-0.9,2.8-1.1
        c1.1-0.3,2.6-0.8,2.6-0.8l1.6-1c0.4-0.2,0.9-0.7,1.4-1.1l1.7,6.3c-0.4-0.1-1-0.1-1.5,0.3c-0.6,0.4-1.3,0.8-1.9,1
        c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.2-0.9,0.2c-0.2,0-0.5,0.1-0.6,0.2c-0.3,0.1-1.9,0.6-2.8,0.9L76,21.3z M78.1,26.7
        c0.8-0.3,2.5-0.8,2.8-0.9c0.2-0.1,0.4-0.1,0.6-0.1c0.3-0.1,0.7-0.1,0.9-0.3c0.1-0.1,0.2-0.1,0.4-0.2c0.5-0.2,1.3-0.6,1.9-1
        c0.4-0.3,1-0.3,1.4-0.2l0.9,3.5c-0.2,0.6-0.5,1.2-0.8,1.3c-0.3,0.1-0.6,0.3-0.9,0.4c-0.4,0.2-0.9,0.5-1.3,0.6
        c-0.4,0.1-0.8,0.3-1.2,0.5c-0.3,0.1-0.5,0.2-0.8,0.3c-0.5,0.2-1.3,0.7-1.8,1.1L78.1,26.7z M80.3,31.9c0.5-0.3,1.4-0.9,1.8-1.1
        c0.3-0.1,0.5-0.2,0.8-0.3c0.4-0.2,0.8-0.3,1.2-0.5c0.4-0.1,0.9-0.4,1.3-0.6c0.3-0.2,0.6-0.3,0.9-0.4c0.4-0.2,0.6-0.6,0.8-1.1
        l1.3,5.1c-0.2,0.3-0.5,0.5-0.8,0.7c-0.3,0.1-0.6,0.2-0.8,0.2c-0.4,0.1-0.8,0.1-1.3,0.4c-0.7,0.4-2.9,1-3.8,1.3L80.3,31.9z
         M81.9,35.8c1-0.3,3.1-0.9,3.8-1.3c0.5-0.3,0.9-0.3,1.3-0.4c0.3-0.1,0.6-0.1,0.9-0.3c0.3-0.1,0.5-0.3,0.7-0.6l1.6,6.1
        c-0.1,0.1-0.3,0.2-0.6,0.4C89,40.3,88,41,87,41.5c-0.3,0.2-0.5,0.3-0.7,0.4C86,42,86,42,85.9,42c-0.1,0-0.2,0-0.6,0
        c-0.4,0.1-0.7,0.1-0.9,0.1L81.9,35.8z M84.6,42.4c0.2,0,0.5,0,0.8-0.1c0.3-0.1,0.5,0,0.5,0c0.1,0,0.2,0,0.5-0.2
        c0.2-0.1,0.4-0.2,0.7-0.4c1-0.5,2-1.3,2.6-1.8c0.2-0.1,0.3-0.3,0.5-0.4l0.9,3.5c-0.4,0.6-0.9,1.3-1.2,1.6
        c-0.3,0.3-0.5,0.4-0.8,0.5c-0.2,0.1-0.4,0.2-0.8,0.4c-0.6,0.4-1.7,0.5-2.3,0.5L84.6,42.4z M86.2,46.5c0.6-0.1,1.7-0.2,2.3-0.6
        c0.3-0.2,0.5-0.3,0.7-0.4c0.3-0.2,0.6-0.3,0.9-0.6c0.3-0.3,0.8-0.9,1.1-1.5l0.9,3.5c-0.5,0.5-1.2,1.3-1.7,1.6
        c-0.7,0.4-0.9,0.5-1.4,0.6c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.1-0.9,0.3-1.2,0.3L86.2,46.5z M87.6,49.8c0.3-0.1,0.8-0.2,1.2-0.3
        c0.1,0,0.2-0.1,0.3-0.1c0.5-0.2,0.8-0.2,1.5-0.6c0.5-0.3,1.2-1,1.7-1.5l1,3.7c-0.3,0.2-0.7,0.4-1.1,0.8c-0.4,0.4-0.4,0.4-0.6,0.4
        c-0.1,0-0.3,0.1-0.7,0.3c-0.7,0.3-1.6,0.4-2.1,0.5L87.6,49.8z M88.9,53.2c0.5,0,1.4-0.1,2.1-0.5c0.4-0.2,0.5-0.2,0.6-0.3
        c0.2-0.1,0.2-0.1,0.7-0.5c0.3-0.3,0.7-0.5,0.9-0.7l0.6,2.2c-0.4,0.3-1.1,1-1.8,1.4c-0.6,0.4-0.7,0.4-0.9,0.5
        c-0.1,0-0.2,0-0.3,0.1c-0.3,0.1-0.8,0.1-1.1,0.1L88.9,53.2z M90,55.8c0.3,0,0.8,0,1-0.1c0.1,0,0.2-0.1,0.3-0.1
        c0.2-0.1,0.3-0.1,1-0.5c0.6-0.4,1.3-1,1.7-1.3l0.3,1.1c-0.2,0.2-0.5,0.4-0.8,0.6c-0.3,0.3-0.6,0.4-0.8,0.6
        c-0.2,0.1-0.4,0.2-0.6,0.4c-0.3,0.2-1,0.4-1.6,0.5L90,55.8z M90.6,57.2c0.5-0.1,1.3-0.2,1.6-0.5c0.2-0.2,0.4-0.3,0.6-0.4
        c0.2-0.1,0.5-0.3,0.9-0.6c0.3-0.2,0.5-0.4,0.7-0.6l0.4,1.4c-0.3,0.3-0.8,0.9-1.1,1c-0.2,0.1-0.4,0.2-0.5,0.3
        c-0.2,0.1-0.3,0.3-0.7,0.3c-0.6,0.1-1.1,0.1-1.3,0.2L90.6,57.2z M91.2,58.7c0.2,0,0.7-0.1,1.2-0.1c0.5-0.1,0.7-0.2,0.9-0.4
        c0.1-0.1,0.2-0.2,0.5-0.3c0.3-0.1,0.8-0.6,1.1-0.9l0.4,1.4c-0.2,0.2-0.5,0.4-1,0.7c-0.4,0.2-0.7,0.3-0.9,0.4
        c-0.2,0.1-0.3,0.1-0.5,0.3c-0.1,0.1-0.6,0.2-1,0.2L91.2,58.7z M91.8,60.1c0.3,0,1-0.1,1.2-0.3c0.1-0.1,0.2-0.1,0.4-0.2
        c0.2-0.1,0.5-0.2,1-0.4c0.4-0.2,0.8-0.5,1-0.6l0.4,1.4c-0.3,0.3-0.8,0.6-1.2,0.7c-0.4,0.1-0.6,0.2-0.7,0.4
        c-0.1,0.1-0.1,0.1-0.2,0.1c-0.3,0.1-0.9,0.1-1.3,0.1L91.8,60.1z M92.4,61.6c0.4,0,1,0,1.2-0.1c0.2,0,0.3-0.1,0.4-0.2
        c0.1-0.1,0.2-0.2,0.5-0.3c0.4-0.1,0.9-0.4,1.2-0.7l0.3,1.1c-0.2,0.1-0.4,0.3-0.6,0.4c-0.1,0.1-0.3,0.1-0.4,0.1
        c-0.2,0-0.5,0-0.9,0.4c-0.3,0.3-0.9,0.5-1.2,0.5L92.4,61.6z M93,63.1c0.4-0.1,0.9-0.2,1.3-0.6c0.3-0.3,0.5-0.3,0.7-0.3
        c0.2,0,0.3,0,0.5-0.1c0.2-0.1,0.4-0.2,0.6-0.4l0.4,1.3c-0.4,0.4-0.9,0.9-1.1,1c-0.2,0.1-0.3,0.1-0.3,0.2
        c-0.1,0.1-0.2,0.1-0.5,0.1c-0.3,0-0.7,0.1-0.9,0.2L93,63.1z M93.6,64.7c0.2-0.1,0.6-0.2,0.8-0.2c0.4,0,0.5-0.1,0.6-0.2
        c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.1,0.8-0.6,1.1-0.9l0.3,1.2c-0.2,0.2-0.4,0.5-0.6,0.6c-0.1,0.1-0.2,0.1-0.3,0.2
        c-0.2,0.1-0.4,0.2-0.7,0.4c-0.3,0.2-0.7,0.3-1,0.3L93.6,64.7z M94.3,66.3c0.3-0.1,0.8-0.2,1.1-0.4c0.3-0.2,0.5-0.3,0.7-0.4
        c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.3,0.5-0.5l0.3,1c-0.2,0.3-0.5,0.6-0.7,0.7c-0.2,0.1-0.3,0.2-0.4,0.2
        c-0.1,0.1-0.1,0.1-0.3,0.2c-0.3,0.1-0.8,0.2-1.1,0.2L94.3,66.3z M94.7,67.4c0.3,0,0.8-0.1,1.1-0.2c0.2-0.1,0.3-0.2,0.4-0.2
        c0.1-0.1,0.1-0.1,0.3-0.2c0.2-0.1,0.5-0.4,0.7-0.6l0.3,1.1c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.3,0.2-0.4,0.2
        c-0.1,0-0.3,0.1-0.5,0.3c-0.2,0.2-0.6,0.3-0.9,0.4L94.7,67.4z M95.3,68.7c0.3-0.1,0.7-0.2,1-0.4c0.2-0.1,0.3-0.2,0.4-0.2
        c0.1,0,0.3-0.1,0.5-0.3c0.1-0.1,0.3-0.2,0.5-0.3l0.3,1.3c-0.2,0.2-0.5,0.5-0.7,0.6c-0.2,0.1-0.3,0.2-0.4,0.2
        c-0.1,0.1-0.1,0.1-0.3,0.2c-0.2,0-0.5,0.1-0.8,0.2L95.3,68.7z M95.9,70.2c0.2-0.1,0.5-0.1,0.7-0.2c0.3-0.1,0.4-0.1,0.4-0.2
        c0.1-0.1,0.1-0.1,0.3-0.2c0.2-0.1,0.5-0.3,0.7-0.5l0.4,1.5c-0.2,0.1-0.5,0.2-0.8,0.4c-0.4,0.2-0.9,0.4-1.3,0.4L95.9,70.2z
         M96.5,71.7c0.3-0.1,0.8-0.2,1.3-0.4c0.3-0.2,0.6-0.3,0.8-0.4l0.4,1.5c-0.1,0.1-0.3,0.2-0.5,0.3c-0.5,0.2-1,0.7-1.2,1L96.5,71.7z
         M97.4,73.9c0.1-0.2,0.6-0.8,1.1-0.9c0.2-0.1,0.3-0.2,0.4-0.2l0.4,1.6l-1.7,0.4L97.4,73.9z M97.8,74.9l1.7-0.4l0.1,0.6L99,75.4
        L98.2,76L97.8,74.9z M98.3,76.3l0.8-0.6l0.6-0.3l0.4,1.4c0,0-0.1,0-0.1,0c-0.3,0-0.6,0.2-0.9,0.3l-0.2,0.1c0,0-0.1,0-0.1,0
        L98.3,76.3z M98.8,77.4c0,0,0.1,0,0.1-0.1l0.2-0.1c0.3-0.1,0.6-0.3,0.8-0.3c0.1,0,0.1,0,0.2,0l0.3,1.3c-0.2,0.2-0.4,0.4-0.5,0.4
        c-0.1,0-0.3,0-0.4,0.1c0,0-0.1,0-0.1,0L98.8,77.4z M100.3,81.1l-0.8-2c0,0,0.1,0,0.1,0c0.2,0,0.3-0.1,0.4-0.1
        c0.2,0,0.4-0.2,0.6-0.4l0.6,2.2C100.8,80.9,100.5,81.1,100.3,81.1C100.3,81.1,100.3,81.1,100.3,81.1z M100.4,81.3
        c0.2,0,0.5-0.1,0.8-0.2l0.4,1.5c-0.1,0.1-0.3,0.3-0.5,0.3L100.4,81.3z M101.2,83.2c0.2-0.1,0.4-0.2,0.5-0.3l0.4,1.6h-0.4
        L101.2,83.2z M101.8,84.7h0.4l0.7,2.6L101.8,84.7z M119.6,111.6l29.6,27.2l-44.1-39.2l-1.4-1.7L119.6,111.6z M139.4,109.7
        l-20.8-9l-2.8-1.5L139.4,109.7z M211.5,84.8c-0.3,1.2-1.4,6-1,9.5c0.3,3.3,0.4,7.8,0.4,9.1l-1.8-0.2c0.1-0.4,0.1-1.2,0-2.6
        c-0.1-1.1-0.1-2.6-0.1-4.2c0-4.3-0.1-5.3-0.5-7.5c-0.3-1.6-0.4-3.1-0.4-3.7L211.5,84.8z M207.8,85.2c0,0.7,0.1,2.2,0.4,3.8
        c0.4,2.2,0.5,3.2,0.5,7.5c0,1.7,0.1,3.1,0.1,4.2c0.1,1.3,0.1,2.4,0,2.6l-2.6-0.3c0.1-1,0.6-5.1,0.5-6.8c-0.1-1.7-0.9-9-1.1-10.7
        L207.8,85.2z M205.2,85.4c0.2,1.7,1,9,1.1,10.7c0.1,1.7-0.4,5.8-0.5,6.8l-1.5-0.1c0.1-1.4,0.2-5.2,0.4-9.2
        c0.2-3.9-0.3-6.9-0.5-8.1L205.2,85.4z M204,85.5c0.2,1.1,0.7,4.1,0.5,8c-0.2,4-0.3,7.8-0.4,9.2l-1.3-0.1c0.1-1,0.3-3.5,0.2-5.8
        c0-1.7-0.3-3.7-0.5-5.4c-0.2-1.3-0.3-2.5-0.3-3c0.1-0.8,0.2-2.3,0.3-2.9L204,85.5z M201.3,88.8c0-1.7,0.1-2.6,0.1-3.1l1-0.1
        c-0.1,0.6-0.2,2-0.3,2.8c0,0.5,0.1,1.6,0.3,3c0.2,1.6,0.4,3.7,0.5,5.4c0.1,2.3-0.1,4.7-0.2,5.8l-0.8-0.1c0-0.9-0.1-5.3-0.1-7.8
        c0-1.4-0.1-2.2-0.2-2.9C201.4,91,201.3,90.2,201.3,88.8z M201.1,85.8c0,0.5,0,1.4-0.1,3.1c0,1.4,0.1,2.2,0.2,3
        c0.1,0.7,0.2,1.5,0.2,2.9c0,2.5,0.1,6.8,0.1,7.8l-2.7-0.3c-0.1-0.6-0.3-2.7-0.5-4.3c-0.2-1.3-0.1-2.1-0.1-3.3c0-0.7,0-1.4,0-2.5
        c0-2.5-0.2-5.3-0.3-6.1L201.1,85.8z M197.7,86.1c0.1,0.7,0.3,3.5,0.3,6.1c0,1,0,1.8,0,2.5c0,1.2-0.1,2.1,0.1,3.4
        c0.2,1.6,0.4,3.6,0.5,4.3l-1.8-0.2c-0.1-0.8-0.2-3,0-4.8c0-0.4,0.1-0.8,0.1-1.2c0.2-1.6,0.3-2.9,0.1-5.7
        c-0.1-2.3-0.2-3.5-0.2-4.2L197.7,86.1z M194.3,86.4l2.2-0.2c0,0.6,0.1,1.9,0.2,4.1c0.2,2.8,0,4.1-0.1,5.7c0,0.4-0.1,0.8-0.1,1.2
        c-0.2,1.7,0,3.9,0,4.8l-1.8-0.2l-0.2-4.2c0,0,0.2-3.1-0.2-6.2C194,89.1,194.2,87.1,194.3,86.4z M193.2,86.5l0.8-0.1
        c-0.1,0.8-0.3,2.8,0,5.1c0.4,3,0.2,6.1,0.2,6.1l0.2,4.1l-0.9-0.1c-0.2-1.2-0.1-3.1-0.1-4.8c0-1.5,0-2.8,0-3.6
        C193.2,91.7,193.2,87.7,193.2,86.5z M192.9,86.5c0,1.2,0,5.2,0.1,6.8c0.1,0.8,0.1,2.2,0,3.6c0,1.8-0.1,3.6,0.1,4.8l-2.5-0.2
        c-0.1-0.9-0.4-3.6-0.5-4.8c-0.1-1.5,0-2.5,0.5-5.2c0.4-2.2-0.1-4.1-0.3-4.8L192.9,86.5z M190.1,86.7c0.2,0.6,0.7,2.5,0.3,4.7
        c-0.5,2.6-0.6,3.7-0.5,5.2c0.1,1.2,0.4,3.8,0.5,4.8l-3.2-0.3c-0.1-0.9-0.2-2.5-0.3-3.3c0-0.1,0-0.3,0-0.6c-0.1-1.3-0.3-3.7,0-5.2
        c0.4-1.6,0-4.4,0-5.1L190.1,86.7z M186.7,87.1c0.1,0.7,0.4,3.4,0,5c-0.4,1.6-0.2,4,0,5.3c0,0.2,0,0.4,0,0.5
        c0,0.7,0.2,2.3,0.3,3.2l-3.1-0.3c0-0.7-0.1-2.5,0.1-4.5c0.2-1.5,0-2.2-0.1-2.9c-0.1-0.5-0.2-1-0.2-1.9c0-1.6,0-3.4-0.1-4.1
        L186.7,87.1z M180.5,87.6l2.9-0.3c0,0.8,0.1,2.5,0.1,4.1c0,0.9,0.1,1.4,0.2,2c0.1,0.7,0.2,1.3,0.1,2.8c-0.2,2-0.2,3.8-0.1,4.5
        l-3.1-0.3c0.2-1.1,0.7-3.7,0.4-4.7c-0.1-0.4-0.2-0.6-0.2-0.8c-0.1-0.3-0.3-0.6-0.4-2.6C180.3,90.1,180.4,88.3,180.5,87.6z
         M180.2,87.6c-0.1,0.7-0.2,2.5-0.1,4.8c0.1,2.1,0.2,2.3,0.4,2.7c0.1,0.2,0.1,0.3,0.2,0.7c0.2,0.9-0.3,3.6-0.5,4.6l-2-0.2
        c-0.1-0.7-0.2-2.1,0-3.6c0.1-1.3,0-1.9,0-2.6c-0.1-0.5-0.1-1-0.1-1.7c0-1.5-0.3-3.5-0.4-4.3L180.2,87.6z M174.2,88.2l3.3-0.3
        c0.1,0.8,0.4,2.9,0.4,4.3c0,0.8,0.1,1.3,0.1,1.8c0.1,0.7,0.1,1.3,0,2.6c-0.1,1.5,0,3,0,3.6l-3.1-0.3c0.1-0.6,0.3-1.9,0.1-2.8
        c-0.1-0.6-0.2-1.1-0.3-1.9c-0.1-0.7-0.2-1.5-0.4-2.6C174,90.6,174.1,88.8,174.2,88.2z M171.8,88.4l2.1-0.2
        c-0.1,0.6-0.2,2.5,0.1,4.4c0.2,1.2,0.3,2,0.4,2.6c0.1,0.7,0.2,1.3,0.3,1.9c0.2,0.8,0,2.1-0.1,2.7l-1.5-0.1l-0.4-0.1
        c0-0.3-0.1-1.3-0.1-2.4c0-0.7-0.1-1.2-0.2-1.9c-0.1-0.6-0.3-1.4-0.4-2.6C171.8,90.8,171.8,89,171.8,88.4z M171.6,88.4
        c0,0.7-0.1,2.5,0.1,4.4c0.1,1.2,0.3,2,0.4,2.6c0.1,0.7,0.2,1.2,0.2,1.8c0,1.1,0.1,2.1,0.1,2.4l-3.2-0.3c0.2-0.3,0.4-0.9,0.4-1.4
        c0-0.2-0.1-0.6-0.2-1.1c-0.2-0.8-0.3-1.7-0.3-2.4c0-0.4,0-0.7,0-1.1c0-0.6-0.1-1.2,0-1.8c0.1-0.8-0.1-2.3-0.2-2.9L171.6,88.4z
         M167.1,91.7c0-1.2,0.1-2.4,0.2-2.9l1.2-0.1c0.1,0.5,0.3,2.1,0.2,2.8c-0.1,0.6-0.1,1.2,0,1.9c0,0.3,0,0.7,0,1.1
        c0,0.7,0.2,1.7,0.4,2.5c0.1,0.4,0.2,0.8,0.2,1c0,0.5-0.2,1.1-0.4,1.4l-1.8-0.2c0-0.7-0.1-3.1-0.1-4.1c0-0.5,0-0.9,0.1-1.3
        C167.1,93.2,167.1,92.6,167.1,91.7z M167,88.8c-0.1,0.6-0.2,1.8-0.2,2.9c0,0.9,0,1.4-0.1,1.9c0,0.4-0.1,0.8-0.1,1.4
        c0,1,0.1,3.3,0.1,4.1l-1.6-0.2v-2.1c0-0.5-0.1-1-0.1-1.5c-0.1-0.5-0.2-1.1-0.2-1.8c0-0.5-0.1-0.9-0.1-1.2c0-0.6-0.1-0.9,0-1.2
        c0.1-0.4,0.2-1.5,0.3-2.1L167,88.8z M164.1,89.1l0.7-0.1c-0.1,0.6-0.2,1.6-0.3,2c-0.1,0.4-0.1,0.7,0,1.3c0,0.3,0.1,0.7,0.1,1.2
        c0,0.8,0.1,1.3,0.2,1.8c0.1,0.5,0.1,1,0.1,1.4v2.1l-0.9-0.1c0-0.7,0.1-2.7,0.1-3.6c0-0.6-0.1-1.1-0.1-1.6
        c-0.1-0.4-0.1-0.9-0.1-1.3C163.9,91.7,164.1,89.9,164.1,89.1z M162.9,89.2l1-0.1c-0.1,0.9-0.2,2.6-0.2,3.2c0,0.4,0.1,0.9,0.1,1.3
        c0.1,0.5,0.1,1,0.1,1.6c0,0.9-0.1,2.9-0.1,3.6l-0.8-0.1c0-0.8,0-2.8,0-3.2c0-0.1,0-0.2-0.1-0.4c-0.1-0.5-0.3-1.3-0.3-2.4
        c0-0.6,0-0.9-0.1-1.2c0-0.4-0.1-0.7,0.1-1.2C162.7,89.9,162.8,89.5,162.9,89.2z M162.6,89.2c-0.1,0.3-0.1,0.6-0.2,1
        c-0.1,0.5-0.1,0.8-0.1,1.3c0,0.3,0.1,0.7,0.1,1.2c0,1.1,0.2,1.9,0.3,2.5c0,0.2,0.1,0.3,0.1,0.4c0.1,0.4,0,2.3,0,3.1l-0.9-0.1
        v-2.2c0-0.9,0-1.5,0.1-2.3c0.1-0.5-0.1-1-0.2-1.5c-0.1-0.4-0.2-0.7-0.2-1c0-0.6,0.4-1.8,0.5-2.3L162.6,89.2z M161.8,89.3
        c-0.2,0.6-0.5,1.7-0.5,2.3c0,0.3,0.1,0.7,0.2,1.1c0.1,0.5,0.3,1,0.2,1.4c-0.1,0.8-0.1,1.4-0.1,2.3v2.1l-2.6-0.3
        c0.4-0.4,0.5-1.1,0.4-2c-0.3-2.6-0.3-4.1-0.3-4.9c0-0.5-0.1-1.3-0.2-1.8L161.8,89.3z M158.7,89.6c0.1,0.5,0.2,1.3,0.2,1.8
        c0,0.8,0,2.4,0.3,4.9c0.1,0.9,0,1.6-0.4,1.9c0,0-0.1,0-0.1,0.1l-3.1-0.3c-0.1-0.9-0.3-2.6-0.3-2.8v-1.3c0-0.4,0-0.8-0.1-1.1
        c0-0.3-0.1-0.6-0.1-0.8c0-0.4-0.3-1.4-0.5-1.9L158.7,89.6z M151.8,90.2l2.5-0.2c0.2,0.6,0.5,1.5,0.5,1.9c0,0.3,0,0.5,0.1,0.8
        c0,0.4,0.1,0.7,0.1,1.1v1.3c0,0.2,0.2,2,0.3,2.8l-3.6-0.4c0.1-0.4,0.3-1.1,0.2-1.5c-0.1-0.7-0.3-1.6-0.3-3.2
        C151.6,91.5,151.8,90.5,151.8,90.2z M151.5,90.2c-0.1,0.4-0.2,1.4-0.2,2.7c0,1.7,0.1,2.6,0.3,3.3c0.1,0.4-0.1,1.1-0.2,1.5
        l-2.5-0.2c0-0.7,0.1-2.2,0.1-2.4c0-0.2-0.1-0.6-0.2-1c-0.1-0.4-0.2-0.9-0.2-1.3c0-0.5-0.1-1.6-0.1-2.1L151.5,90.2z M145.8,93.7
        c0.1-0.3,0.1-0.7,0.1-0.9c0-0.4,0-1.7,0.1-2.1l2.1-0.2c0,0.5,0.1,1.6,0.1,2.1c0,0.3,0.1,0.9,0.2,1.3c0.1,0.4,0.2,0.8,0.2,1
        c0,0.2,0,1.7-0.1,2.4l-2.5-0.2c-0.1-0.5-0.4-2-0.4-2.4C145.7,94.4,145.8,94,145.8,93.7z M143.5,90.9l2.2-0.2
        c0,0.4-0.1,1.6-0.1,2.1c0,0.3-0.1,0.6-0.1,0.9c-0.1,0.3-0.1,0.6-0.1,0.9c0,0.4,0.2,1.8,0.4,2.4l-2.2-0.2c0-0.5-0.1-1.7-0.1-2.1
        c0-0.2,0-0.6-0.1-1.1c0-0.4-0.1-0.9-0.1-1.2C143.4,92.1,143.5,91.3,143.5,90.9z M140.5,91.2l0.7-0.1l2.1-0.2
        c0,0.4-0.1,1.2-0.1,1.6c0,0.3,0,0.7,0.1,1.2c0,0.4,0.1,0.8,0.1,1c0,0.4,0,1.5,0.1,2l-3.3-0.3l0.4-0.8l0,0c0,0-0.1-1.4-0.1-2.4
        C140.4,92.4,140.5,91.5,140.5,91.2z M137.2,91.5l3.1-0.3c0,0.3-0.1,1.2-0.1,2c0,0.9,0.1,2.2,0.1,2.4l-0.4,0.8l-2.5-0.2
        c0-0.8,0-2.2-0.1-2.8C137.1,92.8,137.1,91.9,137.2,91.5z M133.7,93c0-0.2,0.1-0.8,0.1-1.2l3.1-0.3c-0.1,0.5-0.1,1.3,0,1.9
        c0.1,0.6,0.1,2,0.1,2.7l-3.5-0.3c0.1-0.4,0.2-1.1,0.2-1.3c0,0,0-0.2,0-0.3C133.7,93.9,133.7,93.2,133.7,93z M130.4,92.1l3.1-0.3
        c0,0.4-0.1,0.9-0.1,1.1c-0.1,0.2,0,0.8,0,1.4c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.8-0.2,1.3l-2.8-0.3c0-0.5-0.1-1.7-0.1-2V92.1z
         M128,94.4c0-0.4,0.1-1.5,0.2-2.1l2-0.2v1.4c0,0.3,0,1.4,0.1,2l-2.2-0.2V94.4z M127.9,92.3c-0.1,0.6-0.2,1.7-0.2,2.1v0.9
        l-2.1-0.2c0-0.4-0.1-1.1-0.2-1.5c0-0.3-0.2-0.7-0.3-1L127.9,92.3z M121.7,92.9l3.1-0.3c0.1,0.2,0.3,0.7,0.4,1
        c0,0.3,0.1,1,0.2,1.4l-4.1-0.4c0.1-0.3,0.2-0.6,0.3-0.8C121.6,93.7,121.6,93.2,121.7,92.9z M121.4,92.9c0,0.3-0.1,0.7-0.1,0.9
        c0,0.2-0.2,0.6-0.3,0.8l-2.3-0.2v-0.7c0-0.1,0-0.3-0.2-0.5L121.4,92.9z M115.3,93.4l2.8-0.2c0,0,0.1,0,0.1,0.1
        c0.1,0.1,0.2,0.3,0.2,0.4v0.7l-3.1-0.3L115.3,93.4z M112.1,93.7l3-0.3l0,0.6L112.1,93.7z"
          />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlink:href="#SVGID_1_" overflow="visible" />
        </clipPath>
        <circle
          clip-path="url(#SVGID_2_)"
          :fill="color"
          cx="103.7"
          cy="97.9"
          r="84.7"
        />
      </g>
    </g>
  </svg>
</template>
